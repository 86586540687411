/**
 * External dependencies
 */
import styled from 'styled-components';

const Typography = styled.div`
	color: #272727;
	line-height: 1.25;
	letter-spacing: 0.25px;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.2;
		font-weight: normal;
		margin-bottom: 0.75em;
	}
	h1 {
		font-size: 2.5em;
		font-weight: normal;
		max-width: calc(100% - 30px);
	}
	h1 + p {
		font-size: 1.3em;
	}
	h2 {
		font-size: 3.2rem;
		font-weight: normal;
		@media screen and (max-width: ${props => props.theme.breakpointMobile}) {
			font-size: 2em;
		}
	}
	h3 {
		font-size: 1.6em;
	}
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1em;
	}
	h1 + h2 {
		margin-top: 0;
	}
	h2 + h3,
	h3 + h4 {
		margin-top: 1.5em;
	}
	p {
		margin: 0 0 1em;
	}
`;

export default Typography;
