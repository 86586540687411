import React, { useContext } from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';
// import useIntersect from '../../hooks/useIntersect';
import Typography from '../Typography/Typography';
import { AppContext } from '../../hooks/appContext';

// const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100);

const IntersectLink = props => {
	const { post } = props;
	const [state, setState] = useContext(AppContext) || [{ active: [] }, () => {}];
	// let topThreshold;
	// let bottomThreshold;
	// if (typeof window !== `undefined`) {
	// 	if (window.innerHeight > 1080) {
	// 		[topThreshold, bottomThreshold] = [12, 85];
	// 	} else if (window.innerHeight <= 600) {
	// 		[topThreshold, bottomThreshold] = [5, 85];
	// 	} else {
	// 		[topThreshold, bottomThreshold] = [15, 80];
	// 	}
	// }
	// const root =
	// 	typeof window !== `undefined`
	// 		? window.document.getElementById('intersection-observer-root')
	// 		: null;
	// const [ref, entry] = useIntersect({
	// 	root,
	// 	threshold: buildThresholdArray(),
	// 	rootMargin: `-${topThreshold}% 0px -${bottomThreshold}%`,
	// });

	// useEffect(() => {
	// 	if (entry.intersectionRatio > 0.2 && state.active !== post.title) {
	// 		if (state.active[state.active.length - 1] !== post.title) {
	// 			let active = [...state.active, post.title];
	// 			if (active.length > 2) {
	// 				active = active.splice(active.length - 2, active.length);
	// 			}
	// 			setState(prevState => ({ ...prevState, active }));
	// 		}
	// 	}
	// }, [entry]);

	const onClick = () => {
		if (state.active[state.active.length - 1] !== post.title) {
			let active = [...state.active, post.title];
			if (active.length > 2) {
				active = active.splice(active.length - 2, active.length);
			}
			setState(prevState => ({ ...prevState, active }));
		}
	};

	// <Link ref={ref} ratio={entry.intersectionRatio} to={post.path} key={post.title}>
	// </Link>
	return (
		<Typography>
			<TitleUI tabIndex="0" className={post.title === state.active[state.active.length - 1] ? 'active' : ''} onClick={onClick} onFocus={onClick}>
				{post.title}
			</TitleUI>
		</Typography>
	);
};

const TitleUI = styled.h3`
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;
	font-family: 'Wremena', serif;
	margin-top: 0 !important;
	margin-bottom: 48px !important;
	color: #666;
	font-weight: normal;
	&.active {
		background: ${props => props.theme.linkActiveColor};
		color: #222;
	}
	&:hover {
		color: #222;
		text-decoration: underline;
	}
	@media screen and (max-width: ${props => props.theme.breakpointMobile}) {
		margin-bottom: 36px !important;
	}
`;

export default IntersectLink;
