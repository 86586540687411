import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import IntersectLink from '../IntersectLink/IntersectLink';
import { AppContext } from '../../hooks/appContext';

const PostListing = ({ postEdges }) => {
	const postList = [];
	const [state, setState] = useContext(AppContext) || [{ active: [{}] }, () => {}];

	postEdges.forEach(postEdge => {
		postList.push({
			path: postEdge.node.fields.slug,
			tags: postEdge.node.frontmatter.tags,
			cover: postEdge.node.frontmatter.cover,
			title: postEdge.node.frontmatter.title,
			date: postEdge.node.fields.date,
			index: postEdge.node.frontmatter.index,
			description: postEdge.node.frontmatter.description,
			excerpt: postEdge.node.excerpt,
			timeToRead: postEdge.node.timeToRead,
		});
	});

	postList.sort((a, b) => {
		if (a.index < b.index) return -1;
		return 0;
	});

	useEffect(() => {
		if (postList.length) {
			setState({ active: [postList[0].title] });
		}
	}, []);

	return (
		<TextContainerUI>
			{postList.map(post => (
				<IntersectLink post={post} key={post.title} />
			))}
		</TextContainerUI>
	);
};

const TextContainerUI = styled.div`
	display: block;
	height: calc(100vh - 64px);
	margin-top: ${props => props.theme.headerHeightDesktop};
	overflow-y: scroll;
	padding: ${props => props.theme.paddingDesktop};
	// padding-bottom: calc(100vh - 64px - 88px - ${props => props.theme.paddingDesktop});
	position: relative;
	width: 100%;
	z-index: 10;

	::-webkit-scrollbar {
		display: none;
	}

	p {
		font-size: 2rem;
		line-height: 1.2;
	}

	@media screen and (max-width: ${props => props.theme.breakpointMobile}) {
		margin-top: calc(${props => props.theme.headerHeightMobile} + 12px);
		padding: ${props => props.theme.paddingMobile};
		overflow: unset;
		// padding-bottom: calc(
		// 	100vh - ${props => props.theme.headerHeightMobile} - 88px -
		// 		${props => props.theme.paddingDesktop}
		// );
	}
`;

export default PostListing;
